// User object to track patients info
export class User {

    /**
     * Constructor for initial creation
     * 
     * @param {String} firstName - User's firstname.
     * @param {String} lastName - User's lastname.
     * @param {String} email - User's email.
     * @param {String} uid - User's Firebase UID.
     */
    constructor (firstName, lastName, email, uid) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.email = email;
        this.uid = uid;
        this.numTrial = 0;
        this.chosenTrial = null;
        this.trialDay = 0;
        this.errors  = {}; 
        this.attempts = [];
        this.trialActive = false;
        this.newUser = true;
    }

    /**
     * Alternative to second constructor. Helper to save fields derived from Firebase
     * 
     * @param {Number} numTrial - Number of the current trial.
     * @param {Number} chosenTrial - Number of trial days chosen.
     * @param {Number} trialDay - Current trial day.
     * @param {[Number]} errors - Average error for each sketch on every trial day.
     * @param {[<img>]} attempts - Sketch images for every trial day.
     * @param {Boolean} trialActive - Status of current trial (Active or Inactive).
     * @param {Boolean} newUser - Status of on board. Indicates whether the user is new to the app.
     */
    setSupplemental(numTrial, chosenTrial, trialDay, errors, attempts, trialActive, newUser) {
        this.numTrial = numTrial
        this.chosenTrial = chosenTrial;
        this.trialDay = trialDay;
        this.errors = errors;
        this.attempts = attempts;
        this.trialActive = trialActive;
        this.newUser = newUser;
    }

    /**
     * Coverts User object into a string representation: (Firstname Lastname)
     * 
     * @returns String representation of User.
     */
    toString() {
        return this.firstName + " " + this.lastName;
    }
}

/**
 * Converter to facilitate data transition between Firestore document and User object.
 */
export const userConverter = {

    // Convert a User object into a JSON object 
    toFirestore: (user) => {
        return {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            uid: user.uid,
            numTrial: user.numTrial,
            chosenTrial: user.chosenTrial,
            trialDay: user.trialDay,
            errors: user.errors,
            trialActive: user.trialActive,
            newUser: user.newUser
        };
    },

    // Convert Firestore snapsot into a user object
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        let userDoc = new User(
            data.firstName,
            data.lastName,
            data.email,
            data.uid,
        );
        userDoc.setSupplemental(
            data.numTrial, 
            data.chosenTrial, 
            data.trialDay, 
            data.errors, 
            [], 
            data.trialActive,
            data.newUser);
        
        return userDoc;
    }
}



export const trialOptions = [null, 7, 14, 30]; // null is not enrolled in a trial program
